import { Controller } from '@hotwired/stimulus';

import formatCurrency from '../src/format_currency';
import $ from "jquery";

export default class extends Controller {
  static targets = [
    'totalNetValueOutput',
    'netWorthResourceCategory',
    'netWorthResourceCategoryValueOutput',
    'netWorthResourceCategoryEncumbranceOutput',
    'netWorthResourceCategoryNetValueOutput',
    'netWorthResourceAttrNamesRow',
    'netWorthResourceGeneralRow',
    'netWorthResourceCategoryInput',
    'netWorthResourceSubcategoryInput',
    'netWorthResourceValueInput',
    'netWorthResourceEncumbranceInput',
    'netWorthResourceNetValueOutput',
    'termPension',
    'pensionText'
  ];

  connect() {
    this.listenToRemoveNetWorthResourceNoteRows();

    this.element.addEventListener('cocoon:after-insert', () => {
      this.calculate();
    });
    this.element.addEventListener('cocoon:after-remove', () => {
      this.calculate();
    });
    this.element.addEventListener('ajax:success', () => {
      this.calculate();
    });
    this.calculate();

    this.element.addEventListener('cocoon:after-insert', () => {
      this.toggleNetWorthResourceAttrNamesRowsStates();
    });
    this.element.addEventListener('cocoon:after-remove', () => {
      this.toggleNetWorthResourceAttrNamesRowsStates();
    });
    this.toggleNetWorthResourceAttrNamesRowsStates();
    this.termPensionTargets.forEach((termPension) => {
      this.termPensionCheck(termPension);
    });
    this.pensionTextTargets.forEach((pensionText) => {
      this.termPensionCheck(pensionText);
    });
  }

  calculate() {
    let totalNetValue = 0;

    this.netWorthResourceCategoryTargets.forEach((netWorthResourceCategory) => {
      let categoryTotalValue = 0;
      let categoryTotalEncumbrance = 0;
      let categoryTotalNetValue = 0;

      this.netWorthResourceGeneralRowTargets
        .filter(el => netWorthResourceCategory.contains(el))
        .forEach((netWorthResourceGeneralRow) => {
          if (netWorthResourceGeneralRow.style.display === 'none') { // Row was removed by Cocoon
            return;
          }

          let netWorthResourceValue =
            parseFloat(
              this.netWorthResourceValueInputTargets
                .find(el => netWorthResourceGeneralRow.contains(el))
                .value
            );
          if (isNaN(netWorthResourceValue)) {
            netWorthResourceValue = 0;
          }
          let netWorthResourceEncumbrance =
            parseFloat(
              this.netWorthResourceEncumbranceInputTargets
                .find(el => netWorthResourceGeneralRow.contains(el))
                .value
            );
          if (isNaN(netWorthResourceEncumbrance)) {
            netWorthResourceEncumbrance = 0;
          }
          const netWorthResourceNetValue = netWorthResourceValue + netWorthResourceEncumbrance;

          this.netWorthResourceNetValueOutputTargets
            .find(el => netWorthResourceGeneralRow.contains(el))
            .textContent = formatCurrency(netWorthResourceNetValue);

          categoryTotalValue += netWorthResourceValue;
          categoryTotalEncumbrance += netWorthResourceEncumbrance;
          categoryTotalNetValue += netWorthResourceNetValue;
          totalNetValue += netWorthResourceNetValue;
        });

      this.netWorthResourceCategoryValueOutputTargets
        .find(el => netWorthResourceCategory.contains(el))
        .textContent = formatCurrency(categoryTotalValue);
      this.netWorthResourceCategoryEncumbranceOutputTargets
        .find(el => netWorthResourceCategory.contains(el))
        .textContent = formatCurrency(categoryTotalEncumbrance);
      this.netWorthResourceCategoryNetValueOutputTargets
        .find(el => netWorthResourceCategory.contains(el))
        .textContent = formatCurrency(categoryTotalNetValue);
    });

    this.totalNetValueOutputTarget.textContent =
      formatCurrency(totalNetValue);
  }

  listenToRemoveNetWorthResourceNoteRows() {
    this.element.addEventListener('cocoon:before-remove', (e) => {
      if (!e.detail.classList.contains('net-worth-resource-general-row')) {
        return;
      }

      let sibling = e.detail.nextElementSibling;
      while (sibling !== null) {
        if (sibling.classList.contains('net-worth-resource-note-row')) {
          sibling.parentNode.removeChild(sibling);
          break;
        }

        sibling = sibling.nextElementSibling;
      }
    });
  }

  toggleNetWorthResourceAttrNamesRowsStates() {
    this.netWorthResourceAttrNamesRowTargets.forEach((netWorthResourceAttrNamesRow) => {
      const hasAtLeastOneNetWorthResourceGeneralRows =
        this.netWorthResourceGeneralRowTargets.some((netWorthResourceGeneralRow) => {
          if (netWorthResourceGeneralRow.style.display === 'none') { // Row was removed by Cocoon
            return false;
          }

          const netWorthResourceCategoryInput =
            this.netWorthResourceCategoryInputTargets.find((netWorthResourceCategoryInput) => {
              return netWorthResourceGeneralRow.contains(netWorthResourceCategoryInput);
            });
          const netWorthResourceSubcategoryInput =
            this.netWorthResourceSubcategoryInputTargets.find((netWorthResourceSubcategoryInput) => {
              return netWorthResourceGeneralRow.contains(netWorthResourceSubcategoryInput);
            });

          let netWorthResourceCategory = netWorthResourceCategoryInput.value;
          if (netWorthResourceCategory === '') {
            netWorthResourceCategory = undefined;
          }
          let netWorthResourceSubcategory = netWorthResourceSubcategoryInput.value;
          if (netWorthResourceSubcategory === '') {
            netWorthResourceSubcategory = undefined;
          }

          return netWorthResourceCategory === netWorthResourceAttrNamesRow.dataset.netWorthResourceCategory &&
            netWorthResourceSubcategory === netWorthResourceAttrNamesRow.dataset.netWorthResourceSubcategory;
        });

      netWorthResourceAttrNamesRow.hidden = !hasAtLeastOneNetWorthResourceGeneralRows;
    });
  }
  termPensionCheck(eventOrTermPension) {
    let termPension;

    if (eventOrTermPension instanceof Event) {
      termPension = eventOrTermPension.currentTarget;
    } else {
      termPension = eventOrTermPension;
    }

    const subCategory = termPension.dataset.subCategory;
    const rowUnknown = this.termPensionTargets.filter(el => el.dataset.unknownNotVerified === 'true');
    const pensionTermRow = this.termPensionTargets.filter(el => el.dataset.unknownTermOrPension === 'true');
    const pensionTermRowUnknownNotVerified = this.termPensionTargets.filter(el => el.dataset.unknownTermOrPensionNotVerified === 'true');
    const pensionTextUnknownTermOrPension = this.pensionTextTargets.filter(el => el.dataset.unknownTermOrPension === 'true');
    const pensionTextUnknownTermOrPensionNotVerified = this.pensionTextTargets.filter(el => el.dataset.unknownTermOrPensionNotVerified === 'true');
    const pensionTextUnknown = this.pensionTextTargets.filter(el => el.dataset.unknownNotVerified === 'true');
    const netWorthResourceValueTermOrPension =
      this.netWorthResourceValueInputTargets.filter(el => el.dataset.unknownTermOrPension === 'true');
    const netWorthResourceValue =
      this.netWorthResourceValueInputTargets.filter(el => el.dataset.unknownTermOrPension === 'false');
    const netWorthResourceEncumbranceTermOrPension =
      this.netWorthResourceEncumbranceInputTargets.filter(el => el.dataset.unknownTermOrPension === 'true');
    const netWorthResourceEncumbrance =
      this.netWorthResourceEncumbranceInputTargets.filter(el => el.dataset.unknownTermOrPension === 'false');

    for (let k = 0; k < pensionTermRow.length; k++) {
      if (pensionTermRow[k].id === termPension.id && termPension.checked) {
        pensionTermRowUnknownNotVerified[k].checked = false;
        netWorthResourceValueTermOrPension[k].value = 0.00;
        netWorthResourceValueTermOrPension[k].hidden = true;
        netWorthResourceValueTermOrPension[k].parentNode.hidden = true;
        netWorthResourceValueTermOrPension[k].value = 0.00;
        netWorthResourceEncumbranceTermOrPension[k].value = 0.00;
        netWorthResourceEncumbranceTermOrPension[k].disabled = true;
        pensionTextUnknownTermOrPension[k].hidden = false;
        pensionTextUnknownTermOrPensionNotVerified[k].hidden = true;
      }

      if (pensionTermRow[k].checked === false && pensionTermRowUnknownNotVerified[k].checked === false) {
        pensionTermRow[k].disabled = false;
        pensionTermRowUnknownNotVerified[k].disabled = false;
        netWorthResourceValueTermOrPension[k].parentNode.hidden = false;
        netWorthResourceEncumbranceTermOrPension[k].disabled = false;
        netWorthResourceValueTermOrPension[k].hidden = false;
        pensionTextUnknownTermOrPension[k].hidden = true;
      }

    }

    for (let j = 0; j < pensionTermRowUnknownNotVerified.length; j++) {
      if (pensionTermRowUnknownNotVerified[j].id === termPension.id && termPension.checked) {
        pensionTermRow[j].checked = false;
        netWorthResourceValueTermOrPension[j].value = 0.00;
        netWorthResourceValueTermOrPension[j].hidden = true;
        netWorthResourceValueTermOrPension[j].parentNode.hidden = true;
        netWorthResourceValueTermOrPension[j].value = 0.00;
        netWorthResourceEncumbranceTermOrPension[j].value = 0.00;
        netWorthResourceEncumbranceTermOrPension[j].disabled = true;
        pensionTextUnknownTermOrPensionNotVerified[j].hidden = false;
        pensionTextUnknownTermOrPension[j].hidden = true;
      }

      if (pensionTermRow[j].checked === false && pensionTermRowUnknownNotVerified[j].checked === false) {
        pensionTermRow[j].disabled = false;
        pensionTermRowUnknownNotVerified[j].disabled = false;
        netWorthResourceValueTermOrPension[j].parentNode.hidden = false;
        netWorthResourceEncumbranceTermOrPension[j].disabled = false;
        netWorthResourceValueTermOrPension[j].hidden = false;
        pensionTextUnknownTermOrPensionNotVerified[j].hidden = true;
      }

    }

    for (let j = 0; j < rowUnknown.length; j++) {
      if (rowUnknown[j].checked === true) {
        netWorthResourceValue[j].value = 0.00;
        netWorthResourceValue[j].hidden = true;
        netWorthResourceValue[j].parentNode.hidden = true;
        netWorthResourceValue[j].value = 0.00;
        netWorthResourceEncumbrance[j].value = 0.00;
        netWorthResourceEncumbrance[j].disabled = true;
        pensionTextUnknown[j].hidden = false;
      }

      if (rowUnknown[j].checked === false) {
        netWorthResourceValue[j].parentNode.hidden = false;
        netWorthResourceEncumbrance[j].disabled = false;
        netWorthResourceValue[j].hidden = false;
        pensionTextUnknown[j].hidden = true;
      }

    }

  }

  updateOrder(event) {
    const object_id = event.currentTarget.dataset.id
    const direction = event.currentTarget.dataset.direction; // Access the value of data-direction


    const getElementSelector = (prefix, id) => `#${prefix}-${id}`;

    var elements = $('.net-worth-resource-general-row');

    var targetIndex = elements.toArray().findIndex(function (element) {
      return $(element).is(`${getElementSelector('net-worth-resource', object_id)}`);
    });

    var switchRowId = ''

    if (direction == 'up') {
      var switchLine = elements.toArray()[targetIndex - 1]
      switchRowId = $(switchLine).attr('id').replace('net-worth-resource-', '')
    }
    else {
      var switchLine = elements.toArray()[targetIndex + 1]
      switchRowId = $(switchLine).attr('id').replace('net-worth-resource-', '')
    }

    const selected_id = getElementSelector('net-worth-resource', object_id)
    const inputValue = $(selected_id).find('.case_net_worth_net_worth_resources_attributes_subcategory').val();
    const switch_id = $(switchLine).attr('id')
    const inputValue2 = $('#' + switch_id).find('.case_net_worth_net_worth_resources_attributes_subcategory').val()

    if (inputValue === inputValue2) {
      const original = {
        resource: getElementSelector('net-worth-resource', object_id),
        noteLabelRow0: getElementSelector('net-worth-resource-note-label-row-0', object_id),
        noteRow0: getElementSelector('net-worth-resource-note-row-0', object_id),
        noteLabelRow1: getElementSelector('net-worth-resource-note-label-row-1', object_id),
        noteRow1: getElementSelector('net-worth-resource-note-row-1', object_id),
      };

      const switchResource = {
        resource: getElementSelector('net-worth-resource', switchRowId),
        noteLabelRow0: getElementSelector('net-worth-resource-note-label-row-0', switchRowId),
        noteRow0: getElementSelector('net-worth-resource-note-row-0', switchRowId),
        noteLabelRow1: getElementSelector('net-worth-resource-note-label-row-1', switchRowId),
        noteRow1: getElementSelector('net-worth-resource-note-row-1', switchRowId),
      };

      this.swapDivs(original.resource, switchResource.resource);
      this.swapDivs(original.noteLabelRow0, switchResource.noteLabelRow0);
      this.swapDivs(original.noteRow0, switchResource.noteRow0);
      this.swapDivs(original.noteLabelRow1, switchResource.noteLabelRow1);
      this.swapDivs(original.noteRow1, switchResource.noteRow1)
    }
  }


  swapDivs(td1, td2) {

    var values = []
    var values2 = []
    var selectValues = []
    var selectValues2 = []
    var textareaValues = []
    var textareaValues2 = []

    //collecting info from inputs 
    $(td1).find("input").each(function () {
      values.push($(this).val())
    });

    $(td2).find("input").each(function () {
      values2.push($(this).val())
    });

    $(td1).find("select").each(function () {
      selectValues.push($(this).val())
    });

    $(td2).find("select").each(function () {
      selectValues2.push($(this).val())
    });

    $(td1).find("textarea").each(function () {
      textareaValues.push($(this).val())
    });

    $(td2).find("textarea").each(function () {
      textareaValues2.push($(this).val())
    });

    //switch select 2 into standared salect 
    $(td1).find("select").each(function () {
      if ($(this).attr("data-controller") === "select2") {
        $(this).attr("data-controller", '');
        $(this).removeClass("select2-hidden-accessible");
      }
    });

    $(td2).find("select").each(function () {
      if ($(this).attr("data-controller") === "select2") {
        $(this).attr("data-controller", '');
        $(this).removeClass("select2-hidden-accessible");
      }
    });

    // save ids for id swop later
    const td1_id = $(td1).attr('id');
    const td2_id = $(td2).attr('id');

    // save html for swop
    const td1_html = $(td1).html();
    const td2_html = $(td2).html();

    // swop html
    $(td1).html(td2_html);
    $(td2).html(td1_html);

    // swop ids
    $(td1).attr('id', 'temp_id');
    $(td2).attr('id', td1_id);
    $('#temp_id').attr('id', td2_id);

    // refill input info
    $(td2).find("input").each(function (index) {
      if (($(this).val() == null || $(this).val() == '') && (values2[index] != '' && values2[index] != null)) {
        $(this).val(values2[index]);
      }
    });

    $(td1).find("input").each(function (index) {
      if (($(this).val() == null || $(this).val() == '') && (values[index] != '' && values[index] != null)) {
        $(this).val(values[index]);
      }
    });

    // refill select info
    $(td1).find("select").each(function (index) {
      if (($(this).val() == null || $(this).val() == '') && (selectValues[index] != '' && selectValues[index] != null)) {
        $(this).val(selectValues[index]);
      }
    });

    $(td2).find("select").each(function (index) {
      if (($(this).val() == null || $(this).val() == '') && (selectValues2[index] != '' && selectValues2[index] != null)) {
        $(this).val(selectValues2[index]);
      }
    });

    // refill textarea info
    $(td1).find("textarea").each(function (index) {
      if (($(this).val() == null || $(this).val() == '') && (textareaValues[index] != '' && textareaValues[index] != null)) {
        $(this).val(textareaValues[index]);
      }
    });

    $(td2).find("textarea").each(function (index) {
      if (($(this).val() == null || $(this).val() == '') && (textareaValues2[index] != '' && textareaValues2[index] != null)) {
        $(this).val(textareaValues2[index]);
      }
    });

    // add select 2 back to page
    $(td1).find("select").each(function () {
      $(this).closest('td').find('label').remove();
      $(this).attr("data-controller", "select2");
      $(this).addClass("select2-hidden-accessible");
    });

    $(td2).find("select").each(function () {
      $(this).closest('td').find('label').remove();
      $(this).attr("data-controller", "select2");
      $(this).addClass("select2-hidden-accessible");
    });
  }

  updateRowIds() {
    setTimeout(() => {
      const newTableRow = document.getElementById("net-worth-resource-NEW_OBJECT_ID");
      const newTableRowID = (Math.random() + 1).toString(36).substring(7);
      newTableRow.setAttribute("id", `net-worth-resource-${newTableRowID}`)
      newTableRow.setAttribute("data-case-net-worth-form-id-value", newTableRowID)
      newTableRow.querySelector('.action-buttons').querySelectorAll('button').forEach(el => {
        el.setAttribute('data-id', newTableRowID)
      })

      const noteLabelRow0 = document.getElementById("net-worth-resource-note-label-row-0-NEW_OBJECT_ID");
      noteLabelRow0.setAttribute("id", `net-worth-resource-note-label-row-0-${newTableRowID}`)
      const noteRowTextArea0 = document.getElementById("net-worth-resource-note-row-0-NEW_OBJECT_ID");
      noteRowTextArea0.setAttribute("id", `net-worth-resource-note-row-0-${newTableRowID}`)
      const noteLabelRow1 = document.getElementById("net-worth-resource-note-label-row-1-NEW_OBJECT_ID");
      noteLabelRow1.setAttribute("id", `net-worth-resource-note-label-row-1-${newTableRowID}`)
      const noteRowTextArea1 = document.getElementById("net-worth-resource-note-row-1-NEW_OBJECT_ID");
      noteRowTextArea1.setAttribute("id", `net-worth-resource-note-row-1-${newTableRowID}`)
    }, 500)

  }
}
